import request from '@/utils/request'

//获取设备模块卡片统计数据
export function getDeviceData(params) {
    return request({
        url: "bivale/device/device/statisticsArray",
        method: 'get',
        params
    });
}

// 获取设备页卡片对象列表
export function getCardList(params) {
    return request({
        url: "bivale/device/device/listDetail",
        method: 'get',
        params,
    });
}


// 获取总场景设置列表
export function getPointSceneList(data) {
    return request({
        url: "bivale/point/scene/list",
        method: 'post',
        data
    });
}

// 保存场景设置
export function postPointSceneSave(data) {
    return request({
        url: "bivale/point/scene/save",
        method: 'post',
        data
    });
}

// 删除场景设置
export function postPointSceneDelete(params) {
    return request({
        url: "bivale/point/scene/delete",
        method: 'get',
        params
    });
}

// 查询场景设置
export function getPointSceneDetails(id) {
    return request({
        url: `bivale/point/scene/${id}`,
        method: 'get'
    });
}

// 设备模块--详情页--设备详情页
export function getDeviceDetails(params) {
    return request({
        url: "bivale/device/device/settinginfo",
        method: 'get',
        params,
    });
}

// 设备模块--详情页--照明详情页
export function getLightDetails(params) {
    return request({
        url: "bivale/device/device/lightDetail",
        method: 'get',
        params,
    });
}

// 设备模块--详情页--空调详情页
export function getAirConditionDetails(params) {
    return request({
        url: "bivale/device/device/airConditionDetail",
        method: 'get',
        params,
    });
}

// 设备模块--详情页--水冷空调详情页
export function getAirConditionWaterDetails(params) {
    return request({
        url: "bivale/device/device/airConditionWaterDetail",
        method: 'get',
        params,
    });
}


// 设备模块--详情页--环境详情页
export function getEnvironmentDetails(params) {
    return request({
        url: "bivale/device/device/environmentDetail",
        method: 'get',
        params,
    });
}

// 照明模块--设置
// 照明模块--时间表页--列表数据获取
export function lightTimetableListKT(params) {
    return request({
        url: 'bivale/history/entrance/schedule/listKT',
        method: 'get',
        params
    });
}

// 照明模块--时间表页--获取右侧页面详细数据
export function getLightTimetableData(params) {
    console.log("时间表页 params>>>",params);
    return request({
        url: 'bivale/device/device/bacsSchDvList',
        method: 'get',
        params
    });
}

// 照明模块--间歇运行控制表页--获取右侧页面详细数据
export function getIntermittentInfo(params) {
    return request({
        url: 'bivale/device/device/intermittentInfo',
        method: 'get',
        params
    });
}


// 设备模块--设备联动控制--获取右侧页面设备联动控制关联设备信息
export function getInterlockOutputInfo(params) {
    return request({
        url: 'bivale/device/device/interlockOutputInfo',
        method: 'get',
        params
    });
}


// 设备模块--设备时间设置趋势组--获取时间设置趋势组关联设备信息
export function getTimeTrendDeviceInfo(params) {
    return request({
        url: 'bivale/device/device/timeTrendDeviceInfo',
        method: 'get',
        params
    });
}

// 设备模块--设备趋势组--获取趋势组关联设备信息
export function getTrendDeviceInfo(params) {
    return request({
        url: 'bivale/device/device/trendDeviceInfo',
        method: 'get',
        params
    });
}

// 设备模块--设备季节切换控制--获取右侧页面设备季节切换关联设备信息
export function getSeasonDeviceInfo(params) {
    return request({
        url: 'bivale/device/device/seasonDeviceInfo',
        method: 'get',
        params
    });
}


// 设备模块--数据比较趋势图表--获取趋势数据
export function getTrendData(params) {
    return request({
        url: 'bivale/device/device/trendData',
        method: 'get',
        params
    });
}

// 设备模块--数据比较趋势图表--获取趋势数据参数
export function getDataCompareTrendPar(params) {
    return request({
        url: 'bivale/device/device/trendDataPar',
        method: 'get',
        params
    });
}

// 设备模块--时间设置趋势图表--获取趋势数据
export function getTimeTrendData(params) {
    return request({
        url: 'bivale/device/device/timeTrendData',
        method: 'get',
        params
    });
}

// 设备模块--设备联动控制--获取右侧页面设备联动控制信息
export function getLinkageCtrlInfo(params) {
    return request({
        url: 'bivale/device/device/linkageCtrlInfo',
        method: 'get',
        params
    });
}

// 设备模块--设备季节切换控制--获取右侧页面设备季节切换信息
export function getSeasonCtrlInfo(params) {
    return request({
        url: 'bivale/device/device/seasonCtrlInfo',
        method: 'get',
        params
    });
}

// 设备模块--设备季节切换控制--获取空调季节切换控制设置信息
export function getSeasonCtrlSetInfo(params) {
    return request({
        url: 'bivale/device/device/airConditionSetInfo',
        method: 'get',
        params
    });
}

// 设备模块--设备季节切换控制--获取空调季节设置信息
export function getAirConditionSeasonInfo(params) {
    return request({
        url: 'bivale/device/device/airConditionSeasonInfo',
        method: 'get',
        params
    });
}


// 设备模块--设备间歇运转控制--获取右侧页面设备间歇运转控制信息
export function getIntermittentCtrlInfo(params) {
    return request({
        url: 'bivale/device/device/intermittentCtrlInfo',
        method: 'get',
        params
    });
}

// 设备模块--设备空调舒适性控制--获取右侧页面空调舒适性控制关联设备信息
export function getComfortInfo(params) {
    return request({
        url: 'bivale/device/device/comfortInfo',
        method: 'get',
        params
    });
}

// 设备模块--时间表页--获取站点所有未注册设备信息
export function getBacsScheduleAllData(params) {
    return request({
        url: 'bivale/device/device/bacsSchDvAllList',
        method: 'get',
        params
    });
}

// 设备模块--时间表页--时间表变更name的api
export function updateScheduleName(params) {
    return request({
        url: "bivale/history/entrance/scheduleName/update",
        method: 'post',
        params,
    });
}

// 用户操作记录页获取查询数据
export function userRecordData(params) {
    return request({
        url: 'bivale/history/useroperate/listCustom',
        method: 'get',
        params
    });
}

// 获取设备模块卡片统计数据
export function getDeviceCardData(params) {
    return request({
        url: 'bivale/device/device/statisticsArray',
        method: 'get',
        params
    });
  }

// 设备模块--更新设备时间表注册设备列表
export function UpdateregisterList(body) {
    return request({
        url: "bivale/device/device/updateBacsSchDvList",
        method: 'post',
        data: body,
    });
}

// 设备模块--更新设备联动控制
export function updateLinkageCtrlInfo(body) {
    return request({
        url: "bivale/device/device/updateLinkageCtrlInfo",
        method: 'post',
        data: body,
    });
}

// 设备模块--更新设备间歇控制
export function updateIntermittentCtrlInfo(body) {
    return request({
        url: "bivale/device/device/updateIntermittentCtrlInfo",
        method: 'post',
        data: body,
    });
}

//设备模块--更新空调参数、运行模式
export function updateAirConditionSetInfo(body) {
    return request({
        url: "bivale/device/device/updateAirConditionSetInfo",
        method: 'post',
        data: body,
    });
}

//设备模块--更新空调季节信息
export function updateAirConditionSeasonInfo(body) {
    return request({
        url: "bivale/device/device/updateAirConditionSeasonInfo",
        method: 'post',
        data: body,
    });
}

//设备模块--更新照明光照度
export function dimmerSetting(body) {
    return request({
        url: "bivale/device/device/dimmerSetting",
        method: 'post',
        data: body,
    });
}

//设备模块--调光设置
export function updateAnalogOutputObj(body) {
    return request({
        url: "bivale/device/device/updateAnalogOutputObj",
        method: 'post',
        data: body,
    });
}

//设备模块--调光状态设置
export function updateAnalogInputObj(body) {
    return request({
        url: "bivale/device/device/updateAnalogInputObj",
        method: 'post',
        data: body,
    });
}

//设备模块--更新开关设置
export function switchSetting(body) {
    return request({
        url: "bivale/device/device/switchSetting",
        method: 'post',
        data: body,
    });
}

//设备模块--灯光开关设置
export function lightSwitchSetting(body) {
    return request({
        url: "bivale/point/scene/executeControl",
        method: 'post',
        data: body,
    });
}

//设备模块--更新数字信号信息（开关、状态、故障）
export function updateBinaryData(body) {
    return request({
        url: "bivale/device/device/updateBinaryData",
        method: 'post',
        data: body,
    });
}

//设备模块--更新运转模式开关设置
export function switchSettingMultiple(body) {
    return request({
        url: "bivale/device/device/switchSettingMultiple",
        method: 'post',
        data: body,
    });
}

//设备模块--更新运转模式全局设置
export function updateMultipleData(body) {
    return request({
        url: "bivale/device/device/updateMultipleData",
        method: 'post',
        data: body,
    });
}

//设备模块--添加设备趋势组信息
export function insertTrendGrBySite(body) {
    return request({
        url: "bivale/device/device/insertTrendGr",
        method: 'post',
        data: body,
    });
}

//设备模块--更新设备趋势组信息
export function updateTrendGrBySite(body) {
    return request({
        url: "bivale/device/device/updateTrendGr",
        method: 'post',
        data: body,
    });
}

//设备模块--删除设备趋势组信息
export function deleteTrendGrBySite(body) {
    return request({
        url: "bivale/device/device/deleteTrendGr",
        method: 'post',
        data: body,
    });
}

//设备模块--添加设备时间趋势组信息
export function insertTimeTrendGrBySite(body) {
    return request({
        url: "bivale/device/device/insertTimeTrendGr",
        method: 'post',
        data: body,
    });
}

//设备模块--更新设备时间趋势组信息
export function updateTimeTrendGrBySite(body) {
    return request({
        url: "bivale/device/device/updateTimeTrendGr",
        method: 'post',
        data: body,
    });
}

//设备模块--删除设备时间趋势组信息
export function deleteTimeTrendGrBySite(body) {
    return request({
        url: "bivale/device/device/deleteTimeTrendGr",
        method: 'post',
        data: body,
    });
}

// 设备模块--设备空调舒适性控制--获取空调舒适性控制信息
export function getComfortCtrlInfo(params) {
    return request({
        url: 'bivale/device/device/comfortCtrlInfo',
        method: 'get',
        params
    });
}

// 设备模块--更新设备空调舒适性
export function updateComfortCtrlInfo(body) {
    return request({
        url: "bivale/device/device/updateComfortCtrlInfo",
        method: 'post',
        data: body,
    });
}

// 设备模块--更新设备名称
export function updateDeviceName(body) {
    return request({
        url: "bivale/device/device/updateDeviceName",
        method: 'post',
        data: body,
    });
}

// 设备模块--取设备状态名称列表 2022-6-30
export function getStatusNameList(params) {
    return request({
        url: 'bivale/device/device/statusName',
        method: 'get',
        params
    });
}

// 设备模块--执行时间表页--获取详细信息 2023-1-11
export function getScheduleInfoDetail(params) {
    return request({
        url: 'bivale/device/schedule/infoDetail',
        method: 'get',
        params
    });
}

// 设备模块--时间表页--时间表变更api 2023-1-11
export function updateSchedule(body, query) {
    return request({
        url: "bivale/device/schedule/update",
        method: 'post',
        data: body,
        params: query,
    });
}

// 设备模块--时间表页--复制全部时间表api
export function copyAllSchedule(params) {
    return request({
        url: "bivale/device/schedule/copy",
        method: 'post',
        params,
    });
}

// 设备模块--时间表页--时间表内单条时间复制api
export function copyOneSchedule(params) {
    return request({
        url: "bivale/device/schedule/copyDay",
        method: 'post',
        params,
    });
}

// 获取多联机空调模块卡片运行状态统计数据
export function getDeviceStatusData(params) {
    return request({
        url: 'bivale/device/airConditioner/statisticsArray',
        method: 'get',
        params
    });
}


export function getEdgeSiteCardData(params) {
    return request({
        url: 'bivale/end/history/count/card',
        method: 'post',
        data:params
    });
}

// 多联机空调 -- 设备页卡片数据
export function getDeviceListDetail(params) {
    return request({
        url: 'bivale/device/device/tenant/listDetail',
        method: 'get',
        params
    });
}

// 多联机空调 -- 设备页--空调设置
export function setAirconditionDeviceApi(data) {
    return request({
        url: 'bivale/device/device/setPointParameters',
        method: 'post',
        data
    });
}