<!--电能设置--可编辑表格---->
<template>
  <a-table 
    :columns="columns" 
    :data-source="data" 
    bordered 
    :pagination="false"
    :customRow="rowClick"
    childrenColumnName = []
    rowKey='no'    
  >
    <template
      v-for="col in ['no','name']"
      :slot="col"
      slot-scope="text, record, index"
    >
      <div :key="col">
        <a-input
          v-if="record.editable"
          style="margin: -5px 0"
          :value="text"
          @change="e => handleChange(e.target.value, record.no, col)"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template slot="operation" slot-scope="text, record, index">
      <div class="editable-row-operations">
        <span v-if="record.editable">
          <a @click="() => save(record.no)">保存</a>
          <a-popconfirm title="确定要取消?" @confirm="() => cancel(record.no)">
            <a>取消</a>
          </a-popconfirm>
        </span>
        <span v-else>
          <!-- <a :disabled="editingKey !== ''" @click="() => edit(record.key)">编辑</a>
          <a-popconfirm title="确定要删除?" @confirm="() => deleteItem(record.key)">
            <a >删除</a>
          </a-popconfirm> -->
          <a-button class="buttonEdit" :disabled="editingKey !== ''" @click="() => edit(record.no)">编辑</a-button>
          <a-popconfirm title="确定要删除?" @confirm="() => deleteItem(record.no)">
            <a-button class="buttonDelete" >删除</a-button>
          </a-popconfirm>
        </span>
      </div>
    </template>
  </a-table>
</template>
<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'no',
    width: '10%',
    scopedSlots: { customRender: 'no' },
  },
  {
    title: '名称',
    dataIndex: 'name',
    width: '65%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  props: {
   dataSource:Array,   
  },
  data() {    
    return {
      data:undefined,
      cacheData:undefined,
      columns,
      editingKey: '',
      selectIndex:0,
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total + this.$t("terminaleditor.a16")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false        
      },
    };
  },
  computed: {
    pluginsDataChange () {
      this.cacheData = this.dataSource.map(item => ({ ...item })); 
      const dataStr=JSON.stringify(this.dataSource);
      // console.log("editableTable computed pluginsDataChange:",dataStr); 
      this.data = JSON.parse(dataStr);      
      return dataStr;
    }
  }, 
  watch: {
    pluginsDataChange: function (val){
      // console.log("editableTable watch pluginsDataChange:",val);  
      this.editingKey = '';
    },
  },
  methods: {
    rowClick(record, index) {
      return {
        // 自定义属性，也就是官方文档中的props，可通过条件来控制样式
        style: {
          // 设置字体颜色
          // 'color': record.id === this.leftAlignId ? '#fff' : '',
          //  设置行背景色
          'background-color': record.no === this.selectIndex ? '#E6F7FF' : ''
          // 设置字体类型
          // 'font-family': 'Microsoft YaHei',
          // // 下划线
          // 'text-decoration': 'underline',
          // // 字体样式-斜体
          // 'font-style': 'italic',
          // // 字体加粗
          // 'font-weight': 'bold'
        },
        on: {
          // 鼠标单击行
          click: event => {
            // 记录当前点击的行标识
            this.$emit("rowClick",record);  
            this.selectIndex = record.no
          }
        } 
      }    
    },

    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.no);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    deleteItem(key){
      // console.log("delete key:",key);
      this.$emit("deleteItem",key);    
    },
    edit(key) {
      console.log("edit key:",key);
      const newData = [...this.data];
      const target = newData.find(item => key === item.no);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.no);
      console.log("save target:",target);
      this.$emit("save",target);
      const targetCache = newCacheData.find(item => key === item.no);
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.no);
      console.log("cancel target:",target);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.no));
        delete target.editable;
        this.data = newData;
      }
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.buttonEdit{
  background-color: rgb(145, 65, 196);
  color:white;
  margin-right: 8px;
}
.buttonDelete{
  background-color: red;
  color:white;
}
</style>