<!--能源模块--条件组件-->
<template>
  <div id="condition">    
    <a-input class="inputWithIcon" v-model="form.name"  :placeholder="$t('linkage.chooseSignal')">
      <template #addonAfter>
        <a-icon type="select" @click="selectSignal"></a-icon>
      </template>
    </a-input>
    <a-input class="input" v-if="isInput&&visibleExtra" v-model="form.extra"  :placeholder="$t('linkage.hour')"/>
    <a-select class="select" v-if="isInput" v-model="form.compareSymbols" @change="handleChange" :placeholder="$t('linkage.compare')" >
      <a-select-option v-for="item in options" :key="item.key" :value="item.code">
        {{ item.title }}
      </a-select-option>
    </a-select>
    <!-- <a-input class="input" v-model:value="form.value" placeholder="临界值" /> -->
    <a-auto-complete
      class="input"
      v-model="form.value"
      :dataSource="dataSource"
      @focus="onFocus"
      :placeholder="$t('linkage.critical')"
    />
    <div class="mg-delete">            
      <a-icon type="delete"  @click="deleteClick"/>
    </div>  
    <a-modal
      :width="1000"
      :visible="visibleTm"
      @cancel="handleCancel"
      @ok="handleOk"
    >
        <terminalEditor :isInput="isInput" :linkageCondition="linkageConditionEdit"></terminalEditor>  
    </a-modal>
  </div>  
</template>

<script>
import terminalEditor from "./terminalEditorMadal";
import moment from "moment";
import {getStatusNameList} from "../../../../api/device";
import {getLinkageControlRuleByExample,getLinkageControlRuleByObjectIdAndType} from "../../../../api/energy";
export default {
  components: {
    "terminalEditor":terminalEditor,
  },

  name: "energy",
  props: {
    isInput: {
      default:true,
      type: Boolean,
    },
    linkageCondition:Object,
    linkageConditionList:Array,
  },
  data() {
    return {
      dataSource: [],
      list:[],
      select:"",
      visibleTm:false,
      visibleExtra:false,
      options:[
        {key:1,code:"greater",title:this.$t('linkage.greater')},
        {key:2,code:"equal",title:this.$t('linkage.equal')},
        {key:3,code:"less",title:this.$t('linkage.less')}
      ],
      form:{
        andOr:0,
        compareSymbols:"",
        extra:"",
        id:0,//数据库记录ID，若设置为0，则为新增
        name: "",                           
        type: 2,//类型（0：规则组；1：规则；2：输入条件；3：输出）
        parentId:1,//上级ID
        no:1,
        value:"",
        objectId:0,
        objectSiteId:0,
        objectType:"",
        siteId: this.$route.query.id, 
        lastUpdated:new moment().valueOf(),//最后更新时间
      },
      linkageConditionEdit:{},//供编辑的联动条件副本
    };    
  },
  methods: {
    deleteClick(){
      this.$emit("delete",this.linkageCondition);
    },
    onFocus(){
      this.dataSource=this.list;
    },
    handleCancel(){
      this.visibleTm=false;
    },
    handleOk(){
      //检查是否有重复(输入点不判断)
      // if(!this.isInput&&this.linkageConditionList!=null&&this.linkageConditionList.length>0){
      //   for(let i=0;i<this.linkageConditionList.length;i++){
      //     if(this.linkageConditionEdit.no!=this.linkageConditionList[i].no&&this.linkageConditionEdit.name==this.linkageConditionList[i].name){
      //       this.$message.error(this.$t("energy.setting.eneLinkage.repetition"));
      //       return;
      //     }
      //   }
      // }

      //当是输出点时，判断是否存在，存在则提醒
      if(!this.isInput)
        this.isExist(this.linkageConditionEdit);

        //对联动条件赋值
        this.linkageCondition.objectSiteId=this.linkageConditionEdit.objectSiteId;
        this.linkageCondition.objectId=this.linkageConditionEdit.objectId;
        this.linkageCondition.objectType=this.linkageConditionEdit.objectType;
        this.linkageCondition.name = this.linkageConditionEdit.name;
        this.linkageCondition.value="";
        this.visibleTm=false;
    },

    isExist(param){
      this.loading = true;
      console.log("getLinkageControlRuleByObjectIdAndType param",param);
      getLinkageControlRuleByObjectIdAndType(param)
      .then((res) => {
            this.loading = false;
            if(res && res.errorCode == '00'){
              if(res.data!=null&&res.data.length>0){
                console.log("getLinkageControlRuleByObjectIdAndType res.data",res.data);
                this.$message.error("该信号点已经进行过设置");
              }                  
            } else {
                // this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
            }
        })
        .catch((err) => {
            this.loading = false;
        });
    },
    //选择信号点（弹窗选择）
    selectSignal(){
      this.visibleTm=true;
    },
    handleCancel(){
      this.visibleTm=false;
    }, 
    handleChange(){
      console.log("condition handleChange form:",this.form);
    },
    getStatusNameList() {
        let params =  {
            kind: this.form.objectType,
            objectId:this.form.objectId,
        };
        console.log("getStatusNameList Params",params);
        this.loading = true;
        getStatusNameList(params)
        .then((res) => {

            this.loading = false;
            if(res && res.errorCode == '00'){
              if(res.data!=null&&res.data.length>0){
                this.list = [];
                for(let i=0;i<res.data.length;i++){
                  this.list.push(res.data[i].name);
                }
                console.log("getStatusNameList list",this.list);
              }              
                
            } else {
                this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
          
      },
  },
  computed: {
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.linkageCondition);   
      this.linkageConditionEdit = JSON.parse(dataStr);
      console.log("condition computed linkageConditionEdit:",this.linkageConditionEdit);
      this.form=this.linkageCondition;      
      console.log("condition computed form:",this.form);
      this.getStatusNameList();
      return dataStr;
    },
  },
  watch:{
    pluginsDataChange: function (val){
      // this.form = JSON.parse(val);
      // console.log("condition watch form:",this.form);
    },
    linkageCondition:{    
        handler(newName, oldName) {
          switch(newName.objectType){
          case "accumulator":
          case "calc":
          case "virt":
            this.visibleExtra=true;
            break;
        }
      },
      deep: true,
    }
  }
};
</script>

<style scoped>
#condition {
  width: 500px;
  height: 30px;
  display: flex;
  justify-content: left;
  margin: 10px 0px;
}
.cascader {
  margin-right: 10px;
  width: 40%;
  height: 30px;
}
.inputWithIcon {
  margin-right: 10px;
  width: 45%;
  height: 30px;
}
.select {
  margin-right: 10px;
  width: 15%;
  height: 30px;
}
.input {
  margin-right: 10px;
  width: 15%;
  height: 30px;
}
.mg-delete{
  width: 5%;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: red;
  border:1px solid #FFFFFF;
  margin: auto 3px;
}
</style>