<!--设备模块--设备联动控制页--设备编辑页-->
<template>
  <div
    id="terminalEditor"
    :element-loading-text="$t('terminaleditor.a1')"
    ref="terminaleditor"
  >    
    <div class="inquire">
      <a-form-model :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="form_row">
          <a-form-model-item :label="$t('terminaleditor.a38')">
            <a-cascader
               style="width: 250px"
              change-on-select
              :options="options"
              :allowClear="false"
              v-model="form.selectCascader"
              @change="onCascaderChange"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t('terminaleditor.a19')">
            <a-select v-model="form.type" style="width: 150px">
              <a-select-option v-for="item in form.typeOptions" :key="item.no" :value="item.no">
                {{ item.cn }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item :label="$t('general.a16')">
            <a-select v-model="form.key" style="width: 100px">
              <a-select-option v-for="item in form.options" :value="item.en" :key="item.no">
                {{item.cn}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item :label="$t('general.a17')">
            <a-input v-model="form.value"  style="width: 100px" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary"  @click="inquire">{{$t('terminaleditor.a4')}}</a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <div class="main">
      <div class="main-all-vessel" v-loading="allLoading">
        <my-tabletitle>{{$t('terminaleditor.a5')}}</my-tabletitle>
        <a-table
          :row-selection="allrowSelection"
          :rowKey="(record) => record.key"
          :columns="columns"
          :dataSource="alterallList"
          :scroll="{ x: 1300 }"
          :pagination="pagination"
        >
        </a-table>
      </div>
      <div class="main-button-vessel">
        <a-button type="primary" style="width: 120px" @click="add"
          >↓ {{$t('terminaleditor.a6')}}</a-button
        >
        <a-button type="primary" style="width: 120px" @click="Delete"
          >↑ {{$t('terminaleditor.a7')}}</a-button
        >
      </div>
      <div class="main-register-vessel" v-loading="registerLoading">
        <my-tabletitle>{{$t('terminaleditor.a8')}}</my-tabletitle>
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :dataSource="registerList"
          :scroll="{ x: 1300 }"
          :pagination="pagination"
        >
        </a-table>
      </div>
    </div>
    <!-- <div class="footer">
      <a-button
        type="primary"
        style="width: 120px"
        @click="update"
        :loading="buttonloading"
        >{{$t('terminaleditor.a9')}}</a-button
      >
    </div>     -->
  </div>
</template>

<script>
import tabletitle from "../../../../components/Title/tabletitle";
import headerTitle from "../../../../components/Title/headerTitle";
import { getBacsScheduleAllData,UpdateregisterList } from "../../../../api/device";
import {getEnergyDataPointInfoList } from "../../../../api/energy";

export default {   
  props:{
    linkageCondition:Object,
    isInput: {
      default:true,
      type: Boolean,
    },
  },
  data() {
    return {
      name: this.$t('terminaleditor.a2'),
      listMenu: this.$store.getters.oneMenu, 
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }, 
      lastSiteInfo:"",//上次查询站点信息
      lastSiteId:0,//上次查询站点ID
      lastType:0,//上次查询类型信息     
      form: {
        typeOptions:[
          {en:"empty",no:0,cn:""},
          {en:"input",no:1,cn:this.$t('terminaleditor.a39')}, 
          {en:"binary",no:2,cn:this.$t('terminaleditor.a40')}, 
          // {en:"linkage",no:3,cn:"联动控制联锁点"},
          // {en:"analog",no:10,cn:"模拟设置设备"}, 
          // {en:"limit",no:11,cn:"上下限预警设置设备"},
          // {en:"multi",no:12,cn:"运行模式设置设备"},
          // {en:"trend",no:19,cn:"趋势点设置设备"},
        ],
        options:[
          {en:"",no:0,cn:""}, 
          {en:"gr1",no:1,cn:"Gr1"}, 
          {en:"gr2",no:2,cn:"Gr2"},
          {en:"gr3",no:3,cn:"Gr3"}, 
          {en:"dvName",no:4,cn:this.$t('terminaleditor.a17')},
          {en:"signalName",no:5,cn:this.$t('terminaleditor.a18')},
        ],
        key:"",
        value: "",
        type:0,
        selectCascader:[],
      },
      buttonloading: false,
      registerLoading: false,
      allLoading:false,
      registerList: [],
      allList: [],
      alterallList: [],
      pointList:[],
      addList: [],
      deleteList:[],
      allSelectedRowkeys:[],
      selectedRowKeys:[],
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total + this.$t("terminaleditor.a16")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false        
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          ellipsis: true,
          width: 70,
          fixed: "left",
        },
        {
          title: "Gr1",
          dataIndex: "gr1",
          width: 120,
          ellipsis: true,
        },
        {
          title: "Gr2",
          dataIndex: "gr2",
          width: 120,
          ellipsis: true,
        },
        {
          title: "Gr3",
          dataIndex: "gr3",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t('terminaleditor.a17'),
          dataIndex: "dvName",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('terminaleditor.a18'),
          dataIndex: "signalName",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t('terminaleditor.a19'),
          dataIndex: "signalType",
          width: 100,
          ellipsis: true,
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 100,
          ellipsis: true,
        },
      ],
    };
  },
  activated(){

  },
  mounted() {     

  },
  watch: {
  // 监听全部认证终端数据，对数据进行加工
    allList: {
      handler(newName, oldName) {
        this.refreshAllList();
        this.inquireFromAllData();
      },
      deep: true,
    },
  },
  computed: {   
    pluginsDataChange () {
      const dataStr=JSON.stringify(this.linkageCondition);   
      console.log("terminalEditorMadal computed linkageCondition:",this.linkageCondition);
      return dataStr;
    },
    rowSelection() {
      return {
        type:'radio',
        selectedRowKeys:this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys=selectedRowKeys;
          this.deleteList = selectedRows;
        },
      };
    },
    allrowSelection() {
      return {
        type:'radio',
        selectedRowKeys:this.allSelectedRowkeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.allSelectedRowkeys=selectedRowKeys;
          console.log("allrowSelection selectedRows:",selectedRows);
          this.addList = selectedRows;
          console.log("allrowSelection addList:",this.addList);
        },
      };
    }, 
    options() {          
      let siteGrList = [];
      let siteList=[];     
      let options = [];     
      console.log("terminalEditorMadal listMenu:", this.listMenu);       
      if(this.listMenu!=null &&this.listMenu.length>0){
        for(let i=0;i<this.listMenu.length;i++){
          let client = {
                    value: this.listMenu[i].id,
                    label: this.listMenu[i].clientName,
                    children: [],
                  };
          options.push(client);
          siteGrList = this.listMenu[i].siteGrList; 
          if(siteGrList!=null&&siteGrList.length>0){                       
            for(let j=0;j<siteGrList.length;j++){
              let siteGr = {
                    value: siteGrList[j].id,
                    label: siteGrList[j].name,
                    children: []
                  };
              client.children.push(siteGr);
              siteList= siteGrList[j].siteList;
              if(siteList!=null&&siteList.length>0){
                for(let k=0;k<siteList.length;k++){
                  let site = {
                    value: siteList[k].siteServiceTypeID+","+siteList[k].id,
                    label: siteList[k].name,
                    children: []
                  };
                  siteGr.children.push(site);
                }
              }
            }            
          }          
        }
      }
      console.log("terminalEditorMadal options:", options);
      return options;
    },   
  },
  methods: {  
    onCascaderChange(value, selectedOptions){
      console.log("onCascaderChange value:", value);
      console.log("onCascaderChange selectedOptions:", selectedOptions);
      if(selectedOptions.length==3){
        let siteInfo = this.form.selectCascader[2];
        let siteInfos=siteInfo.split(",");
        let siteType=parseInt(siteInfos[0]);//是能源站点还是其他站点 
        if(siteType==8){
          this.form.typeOptions=[
                                  {en:"empty",no:0,cn:""},
                                  {en:"accumulator",no:1,cn:this.$t('terminaleditor.a41')}, 
                                  {en:"calc",no:2,cn:this.$t('terminaleditor.a42')}, 
                                  {en:"virt",no:3,cn:this.$t('terminaleditor.a43')},
                                ];
        }
        else if(this.isInput){
          this.form.typeOptions=[{en:"empty",no:0,cn:""},
                                {en:"input",no:1,cn:this.$t('terminaleditor.a39')},
                                {en:"binary",no:2,cn:this.$t('terminaleditor.a40')},
                                {en:"analog",no:10,cn:this.$t('terminaleditor.a44')},
                                {en:"multi",no:12,cn:this.$t('terminaleditor.a45')},];
          this.form.type = 1;

        }else {
          this.form.typeOptions=[{en:"empty",no:0,cn:""},
                                {en:"binary",no:2,cn:this.$t('terminaleditor.a40')},
                                {en:"analog",no:10,cn:this.$t('terminaleditor.a44')},
                                {en:"multi",no:12,cn:this.$t('terminaleditor.a45')},];
          this.form.type = 2;
        }
      }
    },
    refreshAllList() {
      let registerList = this.registerList;
      let allListJSON = JSON.stringify(this.allList);
      let allListTemp = JSON.parse(allListJSON);
      for (let i = 0; i < registerList.length; i++) {
          allListTemp = allListTemp.filter((item) => {
          return item.signalNo !== registerList[i].signalNo;
        });
      }
      this.alterallList = allListTemp;
      for(let i=0;i<this.alterallList.length;i++){
        this.alterallList[i].key=i+1;
      }
      console.log("refreshAllList alterallList:",this.alterallList);
    },
    clearSelected(){
      this.addList = [];      
      this.allSelectedRowkeys=[]; 
      this.deleteList = [];
      this.selectedRowKeys=[];
    },    
    // 页面后退
    goBack() {
      this.$router.go(-1);
    },    
    // 获取当前全部的认证终端数据
    getAlldata() {
      this.allLoading=true;  
      if(this.form.selectCascader==null||this.form.selectCascader.length<3)
        return;
      this.lastSiteInfo = this.form.selectCascader[2];
      this.lastType = this.form.type;
      let siteInfo=this.lastSiteInfo.split(",");
      let siteType=parseInt(siteInfo[0]);//是能源站点还是其他站点
      this.lastSiteId=parseInt(siteInfo[1]);      
      let searchKeyword = [];
      let par ={};
      // if(this.form.key.length>0&&this.form.value.length>0){
      //   searchKeyword.push(this.form.key+","+this.form.value);
      // }
      switch(siteType){
        case 8:
          par = {
            siteId: this.lastSiteId,
            type:this.form.type,        
          };     
          console.log("getEnergyDataPointInfoList par:",par);
          getEnergyDataPointInfoList(par)
            .then((res) => {
              let { data } = res;                    
              this.allList = data;           
              this.allLoading=false;               
              console.log("getEnergyDataPointInfoList allList:",this.allList);         
            })
            .catch((err) => {
              console.log(err);
              this.allLoading=false; 
            });
          break;
        default:
          par = {
            siteId: this.lastSiteId,
            type:this.form.type,
            searchKeyword: searchKeyword,
            pointList: this.pointList.join(","),         
          };     
          console.log("getBacsScheduleAllData par:",par);
          getBacsScheduleAllData(par)
            .then((res) => {
              let { data } = res;                    
              this.allList = data;           
              this.allLoading=false;               
              console.log("getBacsScheduleAllData allList:",this.allList);         
            })
            .catch((err) => {
              console.log(err);
              this.allLoading=false; 
            }); 
          break;
      }            
    },    
    //查询
    inquire(){      
      // console.log("terminalEditorMadal computed linkageCondition:",this.linkageCondition);
      if(this.form.selectCascader==null||this.form.selectCascader.length<3)
        return;
      console.log("inquire lastSiteInfo:",this.lastSiteInfo);   
      console.log("inquire selectCascader:",this.form.selectCascader); 
      if(this.lastSiteInfo==this.form.selectCascader[2]&&this.lastType==this.form.type){        
        this.inquireFromAllData();
      }
      else{
        this.getAlldata();        
      }
        
    },
    //在当前所有数据中查询
    inquireFromAllData(){
      if(this.form.key==""){
        this.refreshAllList(); 
        return ;
      }
      this.alterallList.length=0;
      // console.log("inquireFromAllData allList:",this.allList);
      for(let i=0;i<this.allList.length;i++){
        switch (this.form.key) {
          case "gr1":
            if(this.allList[i].gr1!=null&&this.allList[i].gr1.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);
            break;
          case "gr2":
            if(this.allList[i].gr2!=null&&this.allList[i].gr2.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);
            break;
          case "gr3":
            if(this.allList[i].gr3!=null&&this.allList[i].gr3.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);
            break;
          case "dvName":   
            if(this.allList[i].dvName!=null&&this.allList[i].dvName.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);         
            break;
          case "signalName":   
            if(this.allList[i].signalName!=null&&this.allList[i].signalName.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);         
            break;
        }
        for (let i = 0; i < this.registerList.length; i++) {
          this.alterallList = this.alterallList.filter((item) => {
            return item.signalNo !== this.registerList[i].signalNo;
          });
        }
        for(let i=0;i<this.alterallList.length;i++){
          this.alterallList[i].key=i+1;
        }
      }
    },
    // 添加终端
    add() { 
      if(this.addList.length>1 || this.registerList.length>0){
        this.$message.error(this.$t('terminaleditor.a37')); 
        return;
      }      
      if (this.addList.length > 0) {        
        for (let i = 0; i < this.addList.length; i++) {
          this.alterallList = this.alterallList.filter((item) => {
            return item.signalNo !== this.addList[i].signalNo;
          });
        }
        //对联动条件赋值
        this.linkageCondition.objectSiteId=this.lastSiteId;
        this.linkageCondition.objectId=this.addList[0].signalNo;
        this.linkageCondition.objectType=this.addList[0].signalKind;
        this.linkageCondition.name = this.addList[0].signalName;

        this.registerList = this.registerList.concat(this.addList);  
        
        this.clearSelected();        
             
        for (let i = 0; i < this.alterallList.length; i++) {
          this.alterallList[i].key = i + 1;
        }
        for (let i = 0; i < this.registerList.length; i++) {
          this.registerList[i].key = i + 1;
        }
        
      } else {
        this.$error({
          title: this.$t('terminaleditor.a33'),
          content: this.$t('terminaleditor.a34'),
          centered: true,
        });
      }
    },
    // 删除终端
    Delete() {
      if (this.deleteList.length > 0) {        
        for (let i = 0; i < this.deleteList.length; i++) {
          this.registerList = this.registerList.filter(
            (item) => item.signalNo !== this.deleteList[i].signalNo
          );
        }
        this.pointInfo = {};
        this.alterallList = this.alterallList.concat(this.deleteList);
        
        this.clearSelected();
        for(let i=0;i<this.alterallList.length;i++){
          this.alterallList[i].key=i+1;
        }
        for (let i = 0; i < this.registerList.length; i++) {
          this.registerList[i].key = i + 1;
        }
        
      } else {
        this.$error({
          title: this.$t('terminaleditor.a33'),
          content: this.$t('terminaleditor.a34'),
          centered: true,
        });
      }
    },   
    update() {
        this.buttonloading = true;
        let par = {
          siteId:this.id,
          bacsScheduleId: this.nodeId,
          bacsScheduleName: this.nodeName,
          schType:this.schType,
          type:this.form.type,
          bacsSchDvInfos:this.registerList
        };
        console.log("UpdateregisterList par:",par);
        UpdateregisterList(par)
          .then((res) => {
            this.buttonloading = false;
            console.log(res);
            if (res.errorCode == "00") {
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.buttonloading = false;
            console.log(err);
          });
  
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#terminalEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 10px 20px 0px 20px;
}
.inquire {
  height: 50px;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 10px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.main-button-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: auto;
}
.main-button-vessel button {
  margin: 0px 10px;
}
.main-register-vessel {
  overflow: hidden;
}
.form_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.name {
  font-size: 15px;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>