<!--能源模块--条件输入组件-->
<template>
  <div id="conditionInput">
    <div class="item">
      <!-- 竖线 -->
      <!-- <div class="lineCol"></div>     -->
      <div class="index">
        {{no}}         
        <a-icon class="mg-delete" type="delete"  @click="deleteClick"/>
      </div>
      <!-- 竖线 -->
      <div class="lineCol"></div>   
      <!-- 条件组件 --> 
      <div class="condition" >
        <conditionOutput :isInput="isInput" :linkageOutputList="linkageInputList" :type="type" :parentId="parentId"  @delete="deleteLinkageCondition"></conditionOutput>
      </div>
      <!-- 竖线 -->
      <!-- <div class="lineCol"></div>  -->
    </div>
    <!-- 橫线 -->
    <div class="lineRow"></div>
  </div>
</template>

<script>
import conditionOutput from "./conditionOutput.vue";
import moment from "moment";

export default {
  name: "energy",
  props: {
    linkageInput: {}, 
    parentId:Number, 
  },
  components: {
    'conditionOutput':conditionOutput,
  },
  data() {
    return {
      loading: false,
      changeState: false,
      isInput:true,
      no:1,
      type:2,
      linkageInputList:[
        {
          andOr: 0,
          compareSymbols: "greater",
          extra:"",
          id:1,//数据库记录ID，若设置为0，则为新增
          name: "test1",                           
          type: 2,//类型（0：规则组；1：规则；2：输入条件；3：输出）
          parentId:this.parentId,//上级ID
          no:1,
          value:"12",
          objectId:1,
          objectSiteId:1,
          siteId: this.$route.query.id, 
          lastUpdated:new moment().valueOf(),//最后更新时间              
        },
        {
          andOr: 0,
          compareSymbols: "less",
          extra:"",
          id:2,//数据库记录ID，若设置为0，则为新增
          name: "test2",                           
          type: 2,//类型（0：规则组；1：规则；2：输入条件；3：输出）
          parentId:this.parentId,//上级ID
          no:2,
          value:"22",
          objectId:1,
          objectSiteId:1,
          siteId: this.$route.query.id, 
          lastUpdated:new moment().valueOf(),//最后更新时间              
        },
      ],
    };
  },
  methods: {
    deleteLinkageCondition(linkageCondition){
      this.$emit("deleteCondition",linkageCondition);
    },
    deleteClick(){
      this.$emit("deleteLinkageControlRuleSub",this.linkageInput);      
    }
  },
  computed: {
    pluginsDataChange () {
      console.log("conditionInput pluginsDataChange linkageInput:",this.linkageInput);
      if(this.linkageInput!=null){
        this.no=this.linkageInput.no;
        this.linkageInputList=this.linkageInput.linkageControlRuleList;
      }
      const dataStr=JSON.stringify(this.linkageInput);   
      // const linkageInput = JSON.parse(dataStr);
      // if(linkageInput!=null){
      //   this.no=linkageInput.no;
      //     this.linkageInputList=linkageInput.linkageControlRuleList;
      //     console.log("conditionInput computed linkageInputList:",this.linkageInputList);
      // } 
      return dataStr;
    }
  },
  watch:{
    pluginsDataChange: function (val){
      // const linkageInput = JSON.parse(val);
      // if(linkageInput!=null){
      //   this.no=linkageInput.no;
      //   if(linkageInput.linkageControlRuleList!=null)
      //     this.linkageInputList=linkageInput.linkageControlRuleList;
      // } 
    },
  }
};
</script>

<style scoped>
#conditionInput {
  display: flex;  
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
}
.item{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.index{
  /* line-height: 50px; */
  /* width:86px; */
  width:15.8%;
  margin: auto;
  text-align: center;
}
.condition{
  width:84.2%;
}
.lineCol {
  width: 1px;
  /* height: 100%; */
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}
.lineRow {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;  
  /* margin: 12px 0px 8px 0px; */
}
.mg-delete{
  width: 5%;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: red;
  border:1px solid #FFFFFF;
  margin: auto 20px;
}
</style>