<!--电能设置--设备联动设置---->
<template>
  <div id="eneLinkage" v-loading="loading" :element-loading-text="$t('alarm.a0')">     
    <div style="display: flex;width:100%;height:100%;">
      <!--左侧菜单列表，联动控制规则组---->
      <div class="side">     
        <!--左侧菜单列表标题---->   
        <div class="mg">             
          <div class="header-title">
            <span>{{title}}</span> 
          </div>       
          <div class="mg-add">            
            <a-icon type="plus-circle" style="vertical-align:10px;" @click="addClick" :title="addTitle"/>
          </div> 
        </div>
        <!--左侧菜单列表内容---->
        <div class="side-menu">
          <a-menu
            style="width: 256px"
            mode="inline"
            :inline-collapsed="collapsed"
            :open-keys.sync="openKeys"
            @click="handleClick"
          >
            <template v-for="(item,index) in linkageCompositList">
              <a-menu-item :key="index">
                <a-row>
                  <a-col :span="20">
                    <a-icon type="snippets" />                
                    <span>{{ item.name }}</span>
                  </a-col>
                  <a-col :span="4">
                    <a-icon type="delete" @click="deleteClick(item,index)" /> 
                  </a-col>
                </a-row>
              </a-menu-item>
              
              <!-- <sub-menu v-else :key="item.no" :menu-info="item" :fatherMethod="deleteClick"/> -->
            </template>            
          </a-menu>
        </div>        
      </div>

      <!--右侧主内容-空白窗体---->
      <div class="main" ref="main" v-if="selectKey==-1">
          <a-empty>
            <span slot="description"> {{ $t("energy.setting.eneLinkage.empty") }} </span>
          </a-empty>
      </div>
      <!--右侧主内容-规则组详细内容---->
      <div class="main" ref="main" v-else>    
        <div class="linkageGroup">  
          <div class="linkageGroupEdit"  style="">            
            <div class="linkageGroupName" style="">
              <span>{{ $t("energy.setting.eneLinkage.groupName") }}：</span><span>{{selectLinkageGruopName}}</span>
            </div>
            <!--右侧主内容-修改规则组名称---->
            <a-button  shape="circle" icon="edit" size="small" style="margin-top:5px;" @click="updateClick" :disabled="selectKey==-1"  />
            <!--右侧主内容-是否启用---->
            <a-select class="selectEnable" v-model="selectIsEnable" @change="enableStatusChange" >
              <a-select-option v-for="item in optionsEnable" :key="item.key" :value="item.value">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
          <!--右侧主内容-规则列表---->
          <div class="linkageTable">
            <editable-table 
              :dataSource="ruleList"
              @deleteItem = "deleteLinkage"
              @rowClick = "rowClick"
              @save="save"               
            >
            </editable-table>
            <div class="btn-display button">
                <a-button class="buttonOperate" type="primary" @click="addLinkageRule">{{ $t("energy.setting.eneLinkage.add") }}</a-button>
            </div>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="line"></div>
        <div  v-if="visibleSub" class="linkageRule"  style="">
          <div class="linkageRuleName" style="">
            <span>{{selectLinkageRuleName}}</span>
          </div>
          <div class="conditionTable">
            <!-- 右侧主内容-规则详细信息表格 -->
            <conditionTable :linkageRule="selectLinkageRuleCopy"></conditionTable>          
            <div class="btn-display button">
                <a-button class="buttonOperate" type="primary" @click="saveLinkageRuleList">{{ $t("energy.setting.eneLinkage.save") }}</a-button>
            </div>
          </div>
        </div>
      </div>
    <div>
        <a-modal v-model="visible" :title="title" @ok="handleOk" :width='600'>
          <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
          <a-form-model ref="timeTrendGrForm" 
              :model="form" 
              :rules="rules"
              :label-col="formItemLayout.labelCol" 
              :wrapper-col="formItemLayout.wrapperCol"  >
                  
              <a-form-model-item  ref="name" prop="name">
                    <span slot="label">
                    {{title}}&nbsp;
                      <a-tooltip :title='tooltip'>
                      <a-icon type="question-circle-o" />
                      </a-tooltip>
                  </span>
                  <a-input 
                      v-model="form.name"
                      @blur="
                      () => {
                          $refs.name.onFieldBlur();
                      }
                      "
                      :placeholder="inputLabel"
                      :max-length="maxlength"
                  />
              </a-form-model-item>                  
          </a-form-model>
          </div>
        </a-modal>        
      </div>
      <!-- <ene-rpt-point :title="pointSelectTitle" :rptGroupId="selectKey" :rptGroupName="linkageGroup.reportGroupName" :lmKind="lmKind" :modeno="modeno"> </ene-rpt-point> -->
    </div>
  </div>
</template>

<script>
import { getEneReportGrList , 
         deleteLinkageControlRule,
         deleteLinkageControlRuleList,
         getLinkageControlRuleCompositBySiteId,
         updateLinkageControlRuleComposit,
         saveLinkageControlRule,
         addLinkageControlRule } from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import editableTable from "./editableTable.vue";
import SEneReportGroupPoint from "../components/measurePoint/eneBuildReportGroupPoints.vue";
import conditionTable from "../components/linkage/conditionTable.vue";
import moment from "moment";

const formItemLayout = {
  labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
  },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
  components: {
    // 'sub-menu': SubMenu,
    'editable-table':editableTable,
    'ene-rpt-point':SEneReportGroupPoint,
    'conditionTable':conditionTable,
  },
  data() {
      let name = this.$t("energy.setting.eneLinkage.name");
      let addTitle  = this.$t("energy.add") + name;
      let spLabel = this.$t("energy.setting.eneLinkage.spLabel").replace('${label}',name);
      let lenLabel = this.$t("energy.setting.eneLinkage.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',30);
      let inputLabel = this.$t("energy.setting.eneLinkage.inputLabel");
      console.log('inputLabel>>',inputLabel);
      inputLabel = inputLabel.replace('${label}',name);
      console.log('inputLabel2>>',inputLabel);
      let tooltip =this.$t("energy.setting.eneLinkage.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';
      let normalName = (rule, value, callback) => {
          let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
          if(regEn.test(value)) {
              callback(new Error(spLabel));
          } else {
              callback();
          }
      };
    
      return {
        visibleSub:false,
        title:this.$t("energy.setting.eneLinkage.title"),
        name:name,
        loading:false,
        frmloading:false,
        linkageCompositList:[],
        selectKey:-1,
        selectIsEnable:false,
        selectLinkageGruopName:"",
        selectLinkageRuleName:"",
        selectLinkageGruop:{},
        selectLinkageRule:{},
        selectLinkageRuleCopy:{},//要更新的规则对象
        linkageRuleCurr:{},        
        form:{
            id:0,
            name: "",
        },
        result:false,
        Yscroll:{y:400},
        rootSubmenuKeys: ["0"],
        openKeys: ["0"],
        visible:false,
        dataChange:false,
        selChange:false,
        collapsed: false,
        formItemLayout,
        formTailLayout,
        rules: {
            name: [
                { required: true, message: inputLabel, trigger: 'blur' },
                { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ]
        },
        maxlength:30,
        pointSelectVisible:false,
        pointSelectTitle:this.$t("energy.setting.eneLinkage.edit"),
        modeno:20,
        lmKind:3,
        reportGroupType:5,
        addTitle:addTitle,
        tooltip:tooltip,
        inputLabel:inputLabel,
        spLabel:spLabel,
        lenLabel:lenLabel,
        optionsEnable:[
          {key:1,value:1,title:"启用"},
          {key:2,value:0,title:"不启用"},
        ],
      };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    //取联动控制规则组
    this.getLinkageControlRuleComposit();
  },
  computed:{
    ruleList(){
      if(this.selectLinkageGruop!=null&&this.selectLinkageGruop.children!=null)
        console.log("eneLinkage computed selectLinkageGruop children:",this.selectLinkageGruop.children);
        return this.selectLinkageGruop.children;
    }
  },
  mounted() { 
    //取联动控制规则组
    // this.getLinkageControlRuleComposit();
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  watch: {

  },
  methods: {       
    saveLinkageRuleList(){      
      //输出数据是否完整判断
      let linkageOutputList=this.selectLinkageRuleCopy.linkageOutputList;
      let linkageCondition=null;
      if(linkageOutputList!=null&&linkageOutputList.length>0){
        for(let i=0;i<linkageOutputList.length;i++){
          linkageCondition=linkageOutputList[i];
          if(linkageCondition.name==""||linkageCondition.value==""){//存在空值
            this.$message.error(this.$t("energy.setting.eneLinkage.outputAlert"));
            return;
          }
        }
      }
      //输入条件数据是否完整判断
      let linkageInputList =this.selectLinkageRuleCopy.linkageInputList;
      let linkageInput=null;
      if(linkageInputList!=null&&linkageInputList.length>0){
        for(let i=0;i<linkageInputList.length;i++){
          linkageInput=linkageInputList[i];
          if(linkageInput!=null && linkageInput.linkageControlRuleList!=null&&linkageInput.linkageControlRuleList.length>0){
            for(let j=0;j<linkageInput.linkageControlRuleList.length;j++){
              linkageCondition=linkageInput.linkageControlRuleList[j];
              if(linkageCondition!=null){
                if(linkageCondition.name==""||linkageCondition.value==""||linkageCondition.compareSymbols==""){//存在空值
                  this.$message.error(this.$t("energy.setting.eneLinkage.inputAlert"));
                  return;
                }
                else {
                  switch(linkageCondition.objectType){
                    case "accumulator":
                    case "calc":
                    case "virt":
                      if(linkageCondition.extra==""){
                        this.$message.error(this.$t("energy.setting.eneLinkage.inputAlert"));
                        return;
                      }                    
                  break;
                  }
                }
              }
            }
          }
        }
      }
      this.updateLinkageControlRuleComposit(this.selectLinkageRuleCopy);
    },    
    getLinkageControlRuleComposit() {
        let params =  {
            siteId: this.$route.query.id,
        };
        console.log("getLinkageControlRuleCompositBySiteId Params",params);
        this.loading = true;
        getLinkageControlRuleCompositBySiteId(params)
        .then((res) => {
            console.log("getLinkageControlRuleCompositBySiteId Result",res);
            this.loading = false;
            if(res && res.errorCode == '00'){
                this.linkageCompositList = res.data;
            } else {
                this.$message.error(this.$t("energy.setting.eneLinkage.loadFail"));
            }

        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
          
      },
      updateLinkageControlRuleComposit(params){
          console.log("updateLinkageControlRuleComposit params",params);
          this.result = false;
          if(params.deleteLinkageRuleList.length>0){
            this.deleteItemList(params.deleteLinkageRuleList);
          }

          updateLinkageControlRuleComposit(params)
          .then((res) => {
              this.frmloading = false;
              if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
                console.log("updateItem res",res);                 
                  this.visible = false; 
                  this.$message.success(this.$t("general.a6"));
                  //更新成功后重新加载数据
                  this.getLinkageControlRuleComposit();
              }   
              else
                this.$message.error(this.$t("general.a7"));     
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
              this.$message.error(this.$t("general.a7"));
          });
      },      
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let tableHeight = height - 130;
              this.Yscroll.y = tableHeight;
          }, 0);
          
      },
      editReportGroupPoint(){
        this.pointSelectVisible = true;
      },
      handleClick(e) {
          // console.log('handleClick', e);
          this.selectKey = parseInt(e.key);
          if(this.linkageCompositList!=null&&this.linkageCompositList.length>0){
            this.selectLinkageGruop=this.linkageCompositList[this.selectKey];
            // console.log('this.selectLinkageGruop:', this.selectLinkageGruop);
            this.selectLinkageGruopName=this.selectLinkageGruop.name;
            this.selectIsEnable=this.selectLinkageGruop.andOr;//规则组的与或标志位，代表是否启用（与规则条件、输出不一致）
          }
          this.visibleSub=false;
      },
      deleteClick(item,index) {
          console.log('deleteClick', item,index);
          let title = this.$t("energy.setting.eneLinkage.deleLabel").replace('${name}',item.name);
          this.$confirm({
          title: title,
          centered: true,
          onOk: () => {
                this.loading = true;
              console.log('eneLinkage deleteClick',item);
              deleteLinkageControlRule(item)
                .then((res) => {
                  console.log("eneLinkage deleteClick Result",res);
                
                  if(res.errorCode == '00'){
                    this.$message.success(res.msg);
                    //删除成功后重新加载数据
                    this.getLinkageControlRuleComposit();
                  }else{
                    this.$message.error(res.msg);                    
                  }   
                  this.loading = false;   
                })
                .catch((err) => {
                  this.loading = false;
                  console.log(err);
                });
              }
          });
      },      
      //添加规则
      addLinkageRule(){        
        if(this.selectLinkageGruop!=null){
          let no=0;          
          if(this.ruleList!=null){
            //取最大值
            console.log("this.ruleList",this.ruleList);
            for(let i=0;i<this.ruleList.length;i++){
              if(no<this.ruleList[i].no)
                no=this.ruleList[i].no;
            }
          }
          else
            this.ruleList=[];
          
          no++;
          let linkageRule={
            andOr:0,
            compareSymbols:"",
            extra:"",
            id:0,//数据库记录ID，若设置为0，则为新增
            name: this.$t("energy.setting.eneLinkage.rule")+no,                           
            type: 1,//类型（0：规则组；1：规则；2：输入条件；3：输出）
            parentId:this.selectLinkageGruop.id,//上级ID
            no:no,
            value:"",
            objectId:0,
            objectType:0,
            objectSiteId:0,
            siteId: this.$route.query.id, 
            lastUpdated:new moment().valueOf(),//最后更新时间
            linkageInputList:[],
            linkageOutputList:[],
          }      
          this.result = false;
          addLinkageControlRule(linkageRule)
          .then((res) => {
              this.frmloading = false;
              if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
                console.log("addLinkageControlRule res",res);                 
                  this.visible = false; 
                  let rule=res.data;
                  rule.children=[];
                  rule.linkageInputList=[];
                  rule.linkageOutputList=[];
                  this.ruleList.push(rule);
                  this.$message.success(this.$t("general.a6"));
              }   
              else
                this.$message.error(this.$t("general.a7"));     
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
              this.$message.error(this.$t("general.a7"));
          }); 
          
        }      
      },
      //删除规则
      deleteLinkage(no){
        console.log("deleteLinkage no:",no);
        for(let i=0;i<this.ruleList.length;i++){
          if(no == this.ruleList[i].no){
            this.deleteItem(this.ruleList[i]);
            this.ruleList.splice(i,1);
            break;
          }
        }        
      },
      //行点击选中
      rowClick(record){
        this.selectLinkageRule=record;
        //取对象副本
        const dataStr=JSON.stringify(this.selectLinkageRule);   
        this.selectLinkageRuleCopy = JSON.parse(dataStr);
        this.selectLinkageRuleCopy.deleteLinkageRuleList=[];
        
        console.log("rowClick record:",record);
        this.selectLinkageRuleName=record.name;  
        this.visibleSub=true;      
      },
      //保存修改
      save(record){
        this.updateItem(record);         
      },
      deleteItem(params){
        deleteLinkageControlRule(params)
        .then((res) => {
              this.frmloading = false;
              if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
                console.log("deleteItem res",res);                 
                  this.visible = false; 
                  this.$message.success(this.$t("general.a6"));
                  //若删除所选规则项，则不显示规则详细表格
                  if(params.no==this.selectLinkageRuleCopy.no)
                    this.visibleSub=false;
              }                
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
              this.$message.error(this.$t("general.a7"));
          });
      },
      deleteItemList(params){
        deleteLinkageControlRuleList(params)
        .then((res) => {
              this.frmloading = false;
              if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
                console.log("deleteItem res",res);                 
                  this.visible = false; 
                  // this.$message.success(this.$t("general.a6"));
              }                
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
              // this.$message.error(this.$t("general.a7"));
          });
      },
      updateItem(params){
          console.log("updateItem params",params);
          this.result = false;
          saveLinkageControlRule(params)
          .then((res) => {
              this.frmloading = false;
              if(res && (res.errorCode == '00' || res.errorCode == '03')){ 
                console.log("updateItem res",res);                 
                  this.visible = false; 
                  this.$message.success(this.$t("general.a6"));
              }   
              else
                this.$message.error(this.$t("general.a7"));     
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
              this.$message.error(this.$t("general.a7"));
          });
      },
      addItem(id,name,type,parentId,no,value,objId,objSiteId){
        let params = {
              id:id,//数据库记录ID，若设置为0，则为新增
              name: name,                           
              type: type,//类型（0：规则组；1：规则；2：输入条件；3：输出）
              parentId:parentId,//上级
              no:no,
              value:value,
              objId:objId,
              objSiteId:objSiteId,
              siteId: this.$route.query.id, 
              lastUpdated:new moment().valueOf(),//最后更新时间              
          }
          console.log("addItem params",params);
          saveLinkageControlRule(params)
          .then((res) => {
              this.frmloading = false;
              if(res && (res.errorCode == '00' || res.errorCode == '03')){
                  console.log("addItem res",res); 
                  this.visible = false; 
                  this.$message.success(this.$t("general.a6"));
                  //成功后重新加载数据
                  this.getLinkageControlRuleComposit();
              } 
              else
                 this.$message.error(this.$t("general.a7"));
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
              this.$message.error(this.$t("general.a7"));
          });
      },
      //添加规则组
      handleOk(e) {
        this.addItem(this.form.id,this.form.name,0,0,0,"",0,0); 
      },
      addClick(e) {
        console.log('addClick', e);
        this.form.name = '';
        this.form.id = 0;
        this.visible = true;
        
      },
      updateClick() {
        console.log('updateClick', this.form);
        this.form.name = this.selectLinkageGruopName;
        this.form.id = this.selectLinkageGruop.id;
        this.visible = true;
      },  
      enableStatusChange(e){
        console.log("enableStatusChange e",e);
        this.selectLinkageGruop.andOr=e;
        this.updateItem(this.selectLinkageGruop);
      },      
  },
};
</script>

<style scoped>
#eneLinkage {
  width: 100%;
  height: 100%;
  padding: 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  background: #7585cd;
  margin-right: 20px;
}
.side {
  width: 280px;
  height: calc(100% - 24px);
  box-shadow: 0px 3px 8px #e7e6e6;
  position: relative;
}
.side-menu{
  max-height:  calc(100% - 50px);
  overflow: auto;
}
.main {
  width: 100%;
  height: calc(100% - 24px);
  padding: 5px;
  box-shadow: 0px 3px 8px #e7e6e6;
}
.mg{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.mg-add{
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 22px;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin: 3px;
}
.mg-add:hover{
  color: #FFFFFF;
  background-color: #7682ce;
  border:1px solid #7682ce;
 
}
.linkageGroup{
  width: 100%;
  height: 50%;
  overflow-y:scroll;
  }
.linkageGroupEdit{
  display: flex;   
  justify-content: flex-start;
  }
.selectEnable{
  margin:3px 10px;
  width: 100px;
  font-size:14px;
}
.linkageGroupName{  
  margin:3px;
  padding:3px;
  font-size:14px;
  line-height:24px;
  }
.linkageTable{
  padding: 10px 0;
  margin-left: 10px;
  width: 60%;
  
}
.conditionTable{
  padding: 10px 0;
  margin-left: 10px;
  width: 95%;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  margin: 12px 0px 8px 0px;
}
.linkageRule{
  width: 100%;
  height: 50%;
  overflow-y:scroll;
}
.linkageRuleName{
  margin:3px;
  padding:3px;
  font-size:14px;
  line-height:24px;
  }
.btn-display{
  display: flex; 
  justify-content: flex-end;  
}
.button{
  padding: 10px 0;  
}
.buttonOperate{
  background-color: rgb(145, 65, 196);
}
</style>