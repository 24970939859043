<!--能源模块--条件组件表格-->
<template>
  <div id="conditionTable">
    <!-- 表头 -->
    <conditionHeader @addItem = "addItem"></conditionHeader>
    <div class="item">
      <!-- 竖线 -->
      <div class="lineCol"></div>    
      <!-- 条件组件 --> 
      <div  class="conditionInput"> 
        <div v-if="linkageInputList && linkageInputList.length > 0">
          <conditionInput 
            v-for="linkageControlRuleSub in linkageInputList" 
            :text="linkageControlRuleSub.no" 
            :key="linkageControlRuleSub.no"
            :linkageInput="linkageControlRuleSub"
            :parentId="parentId"
             @deleteCondition="deleteLinkageCondition"
             @deleteLinkageControlRuleSub="deleteLinkageControlRuleSub"
          >
          </conditionInput>
        </div> 
      </div> 
      <!-- 竖线 -->
      <div class="lineCol"></div>    
      <div class="select">
        <a-select class="selectA" v-model="andOr" :placeholder="$t('linkage.compare')" >
          <a-select-option v-for="item in options" :key="item.key" :value="item.code">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>     
      <!-- 竖线 -->
      <div class="lineCol"></div>    
      <!-- 输出控件 -->
      <div class="conditionOutput">
        <conditionOutput 
          :linkageOutputList="linkageOutputList" 
          :parentId="parentId" 
          @delete="deleteLinkageCondition"
        >
        </conditionOutput>  
      </div>
      <!-- 竖线 -->
      <div class="lineCol"></div>    
    </div>
    <!-- 橫线 -->
    <div class="lineRow"></div>
  </div>
</template>

<script>
import conditionHeader from "./conditionHeader.vue";
import conditionInput from "./conditionInput.vue";
import conditionOutput from "./conditionOutput.vue";
import moment from "moment";

export default {
  name: "energy",
  props: {
    linkageRule:{},
  },
  components: {
    'conditionHeader':conditionHeader,
    'conditionInput':conditionInput,
    'conditionOutput':conditionOutput,
  },
  data() {
    return {
      loading: false,
      changeState: false,    
      parentId:0,  
      linkageInputList:[
        {
          no:1,
          linkageControlRuleList:[
            {
              andOr: 0,
              compareSymbols: "less",
              extra:"",
              id:1,//数据库记录ID，若设置为0，则为新增
              name: "",                           
              type: 3,//类型（0：规则组；1：规则；2：输入条件；3：输出）
              parentId:1,//上级
              no:1,
              value:"",
              objId:1,
              objSiteId:1,
              siteId: this.$route.query.id, 
              lastUpdated:new moment().valueOf(),//最后更新时间              
            },
          ],      
        }
      ],
      linkageControlRuleList:[],
      andOr:1,
      options:[
        {key:1,code:0,title:this.$t('linkage.and')},
        {key:2,code:1,title:this.$t('linkage.or')},
      ],
    };
  },
  methods: {
    deleteLinkageCondition(linkageCondition){
      //加入到删除列表
      this.linkageRule.deleteLinkageRuleList.push(linkageCondition);
    },
    deleteLinkageControlRuleSub(linkageControlRuleSub){

      //将输入条件加入删除列表
      if(linkageControlRuleSub!=null){
        let linkageControlRuleList = linkageControlRuleSub.linkageControlRuleList;
        if(linkageControlRuleList!=null&&linkageControlRuleList.length>0)
        for(let i=0;i<linkageControlRuleList.length;i++)
          this.linkageRule.deleteLinkageRuleList.push(linkageControlRuleList[i]);
      }
      //删除界面对应项
      if(this.linkageInputList!=null&&this.linkageInputList.length>0){
        for(let i=0;i<this.linkageInputList.length;i++){
          if(this.linkageInputList[i].no==linkageControlRuleSub.no){
            this.linkageInputList.splice(i,1);
            break;
          }
        }
      }

    },
    addItem(msg){
      console.log("addItem msg:",msg);
      console.log("addItem linkageRule:",this.linkageRule);
      console.log("addItem linkageInputList:",this.linkageInputList);
      if(this.linkageInputList==null)
        return;
      let no = 0;
      if(this.linkageInputList!=null&&this.linkageInputList.length>0){
        for(let i=0;i<this.linkageInputList.length;i++){
          if(this.linkageInputList[i].no>no)
            no=this.linkageInputList[i].no;
        }
      }
      no++;
      let linkageControlRuleSub={
        no:no,
        linkageControlRuleList:[],
      };
      this.linkageInputList.push(linkageControlRuleSub);
    }
  },
  computed: {
    pluginsDataChange () {
      console.log("conditionTable pluginsDataChange linkageRule:",this.linkageRule);
      
      if(this.linkageRule!=null){
        this.andOr = this.linkageRule.andOr;
        this.linkageOutputList=this.linkageRule.linkageOutputList;
        this.linkageInputList =this.linkageRule.linkageInputList;
        this.parentId = this.linkageRule.id;
      }
      const dataStr=JSON.stringify(this.linkageRule);
      // const linkageRule = JSON.parse(dataStr);
      // if(linkageRule!=null){
      //     this.linkageInputList =linkageRule.linkageInputList;
      //     this.linkageOutputList=linkageRule.linkageOutputList;
      // }     
      return dataStr;
    }
  },
  watch:{
    pluginsDataChange: function (val){
      // const linkageRule = JSON.parse(val);
      // if(linkageRule!=null){
      //   const linkageControlRulePacket =linkageRule.linkageControlRulePacket;
      //   if(linkageControlRulePacket)
      //     if(linkageControlRulePacket.linkageInputList!=null&&linkageControlRulePacket.linkageInputList.length>0)
      //       this.linkageInputList =linkageControlRulePacket.linkageInputList;
      //     this.linkageOutputList=linkageControlRulePacket.linkageOutputList;
      // } 
    },
  }
};
</script>

<style scoped>
#conditionTable {
  /* display: flex;  
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 10px; */
  font-size: 18px;
}
.item{
  height: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.conditionInput{
  width: 59%;  
}
.select{
  width: 8%;
  margin: auto;
}
.selectA{
  width:80%;
  margin: 0px 10%;
}
.conditionOutput{
  width: 32.8%;
  margin: auto;
}
.ruleSub{
  flex-direction: column;
}
.lineRow {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}
.lineCol {
  width: 1px;
  /* height: 100%; */
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}

</style>