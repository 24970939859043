<!--能源模块--条件表头-->
<template>
  <div id="conditionHeader">
    <!-- 橫线 -->
    <div class="lineRow"></div>
    <div class="item">
      <!-- 竖线 -->
      <div class="lineCol"></div>    
      <!-- 文字显示 -->
      <div class="index">{{$t('linkage.index')}}</div>
      <!-- 添加图标 --> 
      <div class="mg-add">            
        <a-icon type="plus-circle"  @click="addClick" :title="addTitle"/>
      </div> 
      <!-- 竖线 -->
      <div class="lineCol"></div>   
      <div class="input">{{$t('linkage.condition')}}</div>
      <!-- 竖线 -->
      <div class="lineCol"></div> 
      <div class="condition">{{$t('linkage.relation')}}</div>
      <!-- 竖线 -->
      <div class="lineCol"></div> 
      <div class="output">{{$t('linkage.output')}}</div>
      <!-- 竖线 -->
      <div class="lineCol"></div> 
    </div>
    <!-- 橫线 -->
    <div class="lineRow"></div>
  </div>
</template>

<script>
import condition from "./condition.vue";

export default {
  name: "energy",
  props: {
    isInput: {
      default:false,
      type: Boolean,
    },
  },
  components: {
    'condition':condition,
  },
  data() {
    return {
      loading: false,
      changeState: false,
      addTitle:this.$t('linkage.addRuleAndInput'),
    };
  },
  methods: {
    //添加规则条件组件
    addClick(){
      this.$emit("addItem",this.$t('linkage.addruleCondition'));
    }
  },
  computed: {

  },
};
</script>

<style scoped>
#conditionHeader {
  height: 40px;
  display: flex;  
  flex-direction: column;
  justify-content: flex-start;
  /* margin: 0px 10px; */
  font-size: 14px;
}
.item{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.index{
  line-height: 30px;
  /* width:60px; */
  width:7%;
  text-align: center;
}
.mg-add{
  /* width: 20px; */
  width:2%;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin: auto 3px;
}
.input{
  line-height: 30px;
  /* width:526px; */
  width:49.5%;
  text-align: center;
}
.condition{
  line-height: 30px;
  /* width:100px; */
  width:8%;
  text-align: center;
}
.output{
  line-height: 30px;
  /* width:526px; */
  width:33%;
  text-align: center;
}
.lineCol {
  width: 1px;
  height: 100%;
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}
.lineRow {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}

</style>