<!--能源模块--条件输出组件-->
<template>
  <div id="conditionOutput">
    <div class="item">
      <!-- 竖线 -->
      <!-- <div class="lineCol"></div>    -->
      <!-- 添加图标 --> 
      <div class="mg-add">            
        <a-icon type="plus-circle" style="vertical-align:10px;" @click="addClick" :title="addTitle"/>
      </div>  
      <!-- 条件组件 --> 
      <div class="condition" v-if="linkageControlRuleList && linkageControlRuleList.length > 0">
        <condition 
          :isInput="isInput" 
          :linkageCondition="linkageControlRule"         
          :linkageConditionList="linkageControlRuleList"  
          v-for="(linkageControlRule,index) in linkageControlRuleList" 
          :key="index"
          @delete="deleteLinkageCondition"
        />
      </div>
      
      <!-- 竖线 -->
      <!-- <div class="lineCol"></div>          -->
    </div>
    <!-- 橫线 -->
    <!-- <div class="lineRow"></div> -->
  </div>
</template>

<script>
import condition from "./condition.vue";
import moment from "moment";

export default {
  name: "energy",
  props: {
    isInput:{
      default:false,
      type:Boolean,
    },
    type:{
      default:3,
      type:Number,
    },
    parentId:Number,
    linkageOutputList: Array,
  },
  components: {
    'condition':condition,
  },
  data() {
    return {
      loading: false,
      changeState: false,
      addTitle:"添加条件",
      linkageControlRuleList:[
        // {
        //   andOr: 0,
        //   compareSymbols: "less",
        //   extra:"",
        //   id:1,//数据库记录ID，若设置为0，则为新增
        //   name: "",                           
        //   type: this.type,//类型（0：规则组；1：规则；2：输入条件；3：输出）
        //   parentId:this.parentId,//上级
        //   no:1,
        //   value:"",
        //   objectId:1,
        //   objectSiteId:1,
        //   siteId: this.$route.query.id, 
        //   lastUpdated:new moment().valueOf(),//最后更新时间              
        // },
        // {
        //   andOr: 0,
        //   compareSymbols: "greater",
        //   extra:"",
        //   id:2,//数据库记录ID，若设置为0，则为新增
        //   name: "",                           
        //   type: this.type,//类型（0：规则组；1：规则；2：输入条件；3：输出）
        //   parentId:this.parentId,//上级
        //   no:1,
        //   value:"",
        //   objId:1,
        //   objSiteId:1,
        //   siteId: this.$route.query.id, 
        //   lastUpdated:new moment().valueOf(),//最后更新时间              
        // },
      ],
    };
  },
  methods: {
    deleteLinkageCondition(linkageCondition){
      console.log("conditionOutput deleteLinkageCondition linkageCondition:",linkageCondition);
      if(linkageCondition!=null)
      {
        for(let i=0;i<this.linkageControlRuleList.length;i++){
          if(this.linkageControlRuleList[i].no==linkageCondition.no)
            this.linkageControlRuleList.splice(i,1);
            
            if(linkageCondition.id>0)//非新增
              this.$emit("delete",linkageCondition);
        }
      }
    },
    addClick(){
      let no = 0;
      if(this.linkageControlRuleList.length>0){
        for(let i=0;i<this.linkageControlRuleList.length;i++){
          if(this.linkageControlRuleList[i].no>no)
            no=this.linkageControlRuleList[i].no;
        }
      }
      no++;
      let linkageControlRule = {
          id:0,//数据库记录ID，若设置为0，则为新增
          name: "",                           
          type: this.type,//类型（0：规则组；1：规则；2：输入条件；3：输出）
          parentId:this.parentId,//上级ID
          no:no,
          value:"",
          objId:1,
          objSiteId:1,
          siteId: this.$route.query.id, 
          andOr: 0,
          compareSymbols: "",
          extra:"",
          lastUpdated:new moment().valueOf(),//最后更新时间              
        };
      this.linkageControlRuleList.push(linkageControlRule);
    }
  },
  computed: {
    pluginsDataChange () {
      console.log("conditionOutput pluginsDataChange linkageOutputList:",this.linkageOutputList);
      if(this.linkageOutputList!=null){
        this.linkageControlRuleList = this.linkageOutputList;
      }
      const dataStr=JSON.stringify(this.linkageOutputList);   
      // this.linkageControlRuleList = JSON.parse(dataStr);
      
      return dataStr;
    }
  },
  watch:{
    pluginsDataChange: function (val){
      // const linkageOutputList = JSON.parse(val);
      // if(linkageOutputList!=null){
      //   this.linkageControlRuleList=linkageOutputList;
      // } 
    },
  },
};
</script>

<style scoped>
#conditionOutput {
  /* width: 526px; */
  display: flex;  
  flex-direction: column;
  justify-content: flex-start;
  font-size: 18px;
}
.item{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.lineCol {
  width: 1px;
  /* height: 100%; */
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}
.lineRow {
  width: 100%;
  height: 1px;
  background-color: #dfe0e0;
  /* margin: 12px 0px 8px 0px; */
}
.condition{
  width: 95%;
}
.mg-add{
  width: 5%;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin: auto 3px;
}

</style>